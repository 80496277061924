import t from '@jetshop/intl'
import { styled } from 'linaria/react'
import React from 'react'

const ErrorContainer = styled('div')`
  max-width: 500px;
  margin: auto;
  text-align: center;
  padding: 3rem 20px;
  h1 {
    font-size: 1.6rem;
    margin-bottom: 10px;
  }
  p {
    font-size: 14px;
    line-height: 1.6;
    max-width: 260px;
    margin: 0 auto 2rem auto;
  }
  a {
    max-width: 200px;
    margin: auto;
  }
`

class UnderConstractionPage extends React.PureComponent {
  render() {
    return (
      <ErrorContainer>
        <h1>{t('Under construction')}</h1>
        <p>{t('Under construction text')}</p>
      </ErrorContainer>
    )
  }
}

export default UnderConstractionPage
